/**
 *  pages.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de Login
 */

import React, { Component, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
// import LogoBlack from "../img/logoblack.png";
import { Consumer } from "../context";
import moment from 'moment-timezone';
import Request from "../core/httpClient";
import CogoToast from 'cogo-toast';
import LoginImage from '../img/PortadaRestaurante.jpg';
import LogoBlack from '../img/LogoAppMosph.png';
/**Socket */
import Socket from '../core/socket';
const request = new Request();

const Login = ({context, history}) => {
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState({ idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 });
	const [message, setMessage] = useState(null);
	const [passStatus, setPassStatus] = useState(false);

	useEffect(() => {
		load();
	}, [])

	async function load() {
		const user = await context.loadUser();
		console.log("User: ", user); 

		if (user) {
			if (user.auth) {
				setUser({
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento
				});

				history.push("/restaurantes/configuracion");
			} else {
				context.logout();
			}	
		}
	}

	async function handleSubmit(event) {
		event.preventDefault();

		setLoading(true);

		let intentos = localStorage.getItem('aW50ZW50b3M=');

		if (intentos) {
			intentos = JSON.parse(intentos);
		} else {
			intentos = { intento: 1, fecha: moment().format() };
		}

		if (moment().diff(moment(intentos.fecha), 'minutes') < 5) {
			if (intentos.intento > 3) {
				CogoToast.warn('Ha hecho más de 3 intentos de ingresar, pruebe nuevamente dentro de 5 minutos');

				setLoading(false);

				return;
			}
		} else {
			intentos = null;
			localStorage.removeItem('aW50ZW50b3M=');
		}

		const form = event.target;
		let data = { user: form.user.value, pwd: form.password.value };

		const response = await request.post("/restaurantes/login", data);
		console.log(response);
		if (response.user) {
			localStorage.removeItem('aW50ZW50b3M=');
			context.login({ id: response.user, auth: true, fecha: moment().format() });
			window.localStorage.setItem("uRest", response.id_usuario);
			Socket.emit('action', { type: 'listAdminRest', data: { iduser: response.id_usuario } });
			history.push("/restaurantes/configuracion");
		} else {
			intentos = {intento: intentos ? parseInt(intentos.intento || 0) + 1 : 1 , fecha: moment().format()};

			console.log(intentos)

			localStorage.setItem('aW50ZW50b3M=', JSON.stringify(intentos));

			setMessage(response.message);
		}
		/*if (form.user.value === 'test' && form.password.value === "1234") {
			console.log('hola');
			//context.login({ id: response.user, auth: true, fecha: moment().format() });
			history.push("/restaurantes/configuracion");
		} else {
			intentos = { intento: intentos ? parseInt(intentos.intento || 0) + 1 : 1, fecha: moment().format() };

			console.log(intentos)

			localStorage.setItem('aW50ZW50b3M=', JSON.stringify(intentos));

			//setMessage(response.message);
		}*/

		setLoading(false);
	}

	function handleReset() {
		window.location = "password/forgot";
	}

	return (
		<div className="login column">
			<Helmet>
				<title>{process.env.REACT_APP_NAME}</title>
			</Helmet>
			<div className="justify-center content">
				<div className="container align-center justify-center">
					<div className="content-gral row-responsive">
						<div className="left-content">
							<img src={LoginImage} alt="" />
						</div>
						<div className="right-content justify-center align-center">
							<div className="card-login column">
								<h1 className="text-center">RESTAURANTES</h1>
								<div className="white-space-32" />
								<div className="responsive-img justify-center">
									<img
										src={LogoBlack}
										alt={`Logo ${process.env.REACT_APP_NAME}`}
										title={`Logo ${process.env.REACT_APP_NAME}`}
									/>
								</div>
								<div className="white-space-8" />
								<h3 className="text-center">Iniciar sesión</h3>
								<div className="white-space-16" />
								<form
									className="column"
									onSubmit={handleSubmit.bind(this)}>
									<div className="input-container">
										<input
											name="user"
											type="text"
											maxLength="64"
											minLength="4"
											autoComplete="off"
											placeholder="Usuario"
											className="weight-semi text-center"
											required
										/>
									</div>
									<div className="white-space-8" />
									<div className="input-container pass-eye">
										{passStatus ?
										<input
											name="password"
											type="text"
											maxLength="64"
											autoComplete="off"
											placeholder="Contraseña"
											className="weight-semi text-center"
											required
											/> :
										<input
											name="password"
											type="password"
											maxLength="64"
											autoComplete="off"
											placeholder="Contraseña"
											className="weight-semi text-center"
											required
										/>}
										{passStatus ? <i className="far fa-eye-slash cursor-pointer" onClick={() => setPassStatus(false)}></i> : <i className="far fa-eye cursor-pointer" onClick={() => setPassStatus(true)}></i>}
									</div>
									<div className="white-space-16" />
									<div className="btn-container">
										<button
											type="submit"
											className="btn btn-primary color-white">
											{loading ? (
												<i className="fas fa-spinner fa-spin font-text" />
											) : (
												<span className="color-white">
													<i className="fas fa-sign-in-alt font-text" />
											&nbsp; ACCEDER
												</span>
											)}
										</button>
									</div>
									{message ? (
										<div className="column">
											<div className="white-space-8" />
											<p className="text-center">
												{message}
											</p>
										</div>
									) : null}
									<div className="white-space-16" />
									<a
										className="justify-center"
										href="#one"
										onClick={handleReset.bind(this)}
									>
										Restablecer contraseña
									</a>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(Consumer(Login));
