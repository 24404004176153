import Socket from 'socket.io-client';
import { URL_API } from './urlsApi';

const socket = Socket(URL_API, {
    pingTimeout: 600000000
});

/*export default Socket(URL_API, {
    key: '5fcae9a7be1dfa53da44c353492bc73d'
}).connect();*/

export default socket;