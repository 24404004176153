import React, { Component } from 'react';
import { Consumer } from "../../context";
import { Link } from "react-router-dom";
import Request from "../../core/httpClient";
const request = new Request();

class SidebarAdministracion extends Component {
    state = {
        menus: [],
        active: 0,
        activeSub: 0
    }

    render() {
        let menus = [];
        if (Array.isArray(this.state.menus)) {
            menus = this.state.menus;
        }

        return (
            <div className="sidebar row" >
                <div className="column content-side">
                    {menus.map((item, index) =>
                        item.recordset ?
                            <React.Fragment key={index}>
                                <button className={`accordion color-white ${this.state.active.toString() === item.id_menu.toString() ? 'active' : ''}`}
                                    onClick={this.parentClick.bind(this, item)}>
                                    {item.parent_name}
                                    <i className="fas fa-angle-down font-regular color-white"></i>
                                </button>
                                {item.recordset.map((list, ind) =>
                                    <div className={`panel ${item.open ? 'maxHeight' : ''}`} key={ind}>
                                        <Link to={list.ruta} className={`row nav-item auto ${this.state.activeSub.toString() === list.id_menu.toString() ? 'active-item' : ''}`} onClick={this.childrenClick.bind(this, list)}>
                                            <div className="font-regular color-white full">
                                                <span className="color-white">{list.nombre}</span>
                                            </div>
                                        </Link>
                                    </div>)}
                            </React.Fragment> : null)}
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.menusLocal();

        let submenu = window.localStorage.getItem('submenu_ope');
        if (submenu) {
            submenu = JSON.parse(submenu);
            this.setState({ activeSub: submenu });
        }
    }

    menusLocal() {
        const menusLocal = window.localStorage.getItem('operacion_menus');
        let parentsOpenLocal = window.localStorage.getItem('parents_open');
        parentsOpenLocal = JSON.parse(parentsOpenLocal);
        if (menusLocal) {
            let menusObject = JSON.parse(menusLocal);
            menusObject = menusObject.map(menuList => {
                if(parentsOpenLocal) {
                    if(parentsOpenLocal.find(p => p.menu.toString() === menuList.id_menu.toString())) {
                        menuList.open = true;
                    }
                    return menuList;
                }
                return menuList;
            })
            this.setState({ menus: menusObject });
        }
    }

    parentClick(item) {
        const menus = this.state.menus.map((menu, i) => {
            if (item.id_menu !== menu.id_menu) {
                menu.open = false
            }
            return menu;
        });

        this.setState({ active: item.id_menu });
        Object.assign(item, { open: !item.open });
        this.setState({ menus });
        window.localStorage.setItem('operacion_menus', JSON.stringify(this.state.menus));
        let parentsOpen = [];
        const parentsOpenLocal = window.localStorage.getItem('parents_open');
        if(parentsOpenLocal) {
            /*parentsOpen = JSON.parse(parentsOpenLocal);
            if(parentsOpen.find(parent => parent.menu.toString() === item.id_menu.toString())) {
                parentsOpen = parentsOpen.filter(parent => parent.menu.toString() !== item.id_menu.toString());
            } else {
                parentsOpen.push({menu: item.id_menu});
            }
            window.localStorage.setItem('parents_open', JSON.stringify(parentsOpen));*/
            window.localStorage.removeItem('parents_open');
            window.localStorage.setItem('parents_open', JSON.stringify([{ menu: item.id_menu }]));
        } else {
            window.localStorage.setItem('parents_open', JSON.stringify([{menu: item.id_menu}]));
        }
    }

    childrenClick(list) {
        this.setState({ activeSub: list.id_menu });
        window.localStorage.setItem('submenu_ope', list.id_menu);
        window.localStorage.setItem('submenu_loc_ope', list.ruta);
    }
}

export default Consumer(SidebarAdministracion);
