/**
 *  index.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Punto de Entrada de React
 * 	@process: 1
*/

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie11';
import WebFont from 'webfontloader';

// Cargar la hoja de estilos

// import './sass/style.scss';

// Método para cargar la fuente de Google de manera asíncrona

WebFont.load({
    google: {
        families: ['Mukta:400,500,600,700', 'sans-serif']
    }
});

ReactDOM.render(<App />, document.getElementById('root'));

// Si quieres que tu aplicación funcione sin conexión y se cargue más rápido, puedes cambiar unregister() a register()

serviceWorker.unregister();
