import React, { useState, useEffect } from 'react';
import NumberFormat from "react-number-format";
import moment from 'moment-timezone';

const PedidosDetail = (props) => {
    const [prevProps, setPrevProps] = useState(props);
    const [pedido, setPedido] = useState({});
    useEffect(() => {
        console.log(props);
        if (props !== prevProps) {
            setPedido(props.pedido);
        }
        setPrevProps(props);
        if (props.getValues) {
            props.getValues(pedido);
        }
    });
    if (props.loadingLineas) {
        return (
            <div className="column justify-center align-center">
                <i className="fas fa-spinner fa-spin"></i>
            </div>
        )
    }
    return (
        <div className="column">
            <div className="white-space-8"></div>
			<div className="input-form-content row full align-center">
				<div className="column label-duo-big">
					<p>
						<b>Propina:</b>
					</p>
				</div>
				<div className="column full">
					<NumberFormat
                        value               =   {pedido.propina}
                        displayType         =   {"text"}
                        thousandSeparator   =   {true}
                        prefix              =   {"$ "}
                        decimalScale        =   {2}
                        fixedDecimalScale   =   {true}
                        className           =   "input input-modals"
                    />
				</div>
				<div className="column label-duo-normal space">
					<p>
						<b>Entrega:</b>
					</p>
				</div>
				<div className="column full">
					<NumberFormat
                        value               =   {pedido.entrega}
                        displayType         =   {"text"}
                        thousandSeparator   =   {true}
                        prefix              =   {"$ "}
                        decimalScale        =   {2}
                        fixedDecimalScale   =   {true}
                        className           =   "input input-modals"
                    />
				</div>
			</div>
			<div className="white-space-8"></div>
			<div className="input-form-content row full align-center">
				<div className="column label-duo-big">
					<p>
						<b>Fecha de entrega:</b>
					</p>
				</div>
				<div className="column full">
					{console.log(pedido.fecha_entrega)}
					<input
						name		=	"fecha_entrega"
						id          =   "fecha_entrega"
						value		=	{moment(pedido.fecha_entrega).format('YYYY-MM-DD HH:mm a')}
						className	=	"input input-modals"
						readOnly
						disabled
					/>
				</div>
				<div className="column label-duo-normal space">
					<p>
						<b>Total:</b>
					</p>
				</div>
				<div className="column full">
					<NumberFormat
                        value               =   {pedido.total}
                        displayType         =   {"text"}
                        thousandSeparator   =   {true}
                        prefix              =   {"$ "}
                        decimalScale        =   {2}
                        fixedDecimalScale   =   {true}
                        className           =   "input input-modals"
                    />
				</div>
			</div>
			<div className="white-space-8"></div>
			<div className="input-form-content row full align-center">
				<div className="column  label-medium">
					<p>
						<b>Estado:</b>
					</p>
				</div>
				<div className="column full">
					<select
                        className="input input-modals"
                        value={pedido.id_estado}
                        onChange={(event) => setPedido({...pedido, id_estado: event.target.value})}>
                        <option value={1}>Pendiente</option>
                        <option value={2}>En proceso</option>
                        <option value={3}>En camino</option>
                        <option value={4}>Entregado</option>
                        <option value={5}>Cancelado</option>
                    </select>
				</div>
			</div>
			<div className="white-space-8"></div>
            <div className="row full justify-center">
                <div className="input-form-content row full align-start">
                    <div className="column label-medium">
                        <p>
                            <b>Comentarios:</b>
                        </p>
                    </div>
                    <div className="column full">
                        <textarea 
                            type		=	"text"
                            name		=	"Propina" 
                            value		=	{pedido.comentarios}
                            className	=	"textarea input input-modals"
                            rows        =   "2"
                            maxLength   =   "100"
                            readOnly    =   {true}
                        />
                    </div>
                </div>
            </div>
            <div className="white-space-8"></div>
            <div className="column full justify-center">
                <div className="row justify-start align-center">
                    <h4>Platos</h4>
                </div>
                <div className="column full">
                    <table className="full">
                        <thead>
                            <tr>
                                <th className="text-left">Código</th>
                                <th className="text-left">Plato</th>
                                <th className="text-left">Cantidad</th>
                                <th className="text-right">Precio</th>
                                <th className="text-right">Importe</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.lineas.map(plato =>
                                <tr key={plato.id_plato}>
                                    <td className="text-left">
                                        <p>{plato.codigo}</p>
                                    </td>
                                    <td className="text-left">
                                        <p>{plato.plato}</p>
                                    </td>
                                    <td className="text-left">
                                        <p>{plato.cantidad}</p>
                                    </td>
                                    <td className="text-right">
                                        <NumberFormat
                                            value               =   {plato.precio}
                                            displayType         =   {"text"}
                                            thousandSeparator   =   {true}
                                            prefix              =   {"$ "}
                                            decimalScale        =   {2}
                                            fixedDecimalScale   =   {true}
                                        />
                                    </td>
                                    <td className="text-right">
                                        <NumberFormat
                                            value               =   {plato.monto}
                                            displayType         =   {"text"}
                                            thousandSeparator   =   {true}
                                            prefix              =   {"$ "}
                                            decimalScale        =   {2}
                                            fixedDecimalScale   =   {true}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PedidosDetail;
