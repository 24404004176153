import React, { Component } from 'react';
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";

const request = new Request();


class SubFRaccPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
			subfraccionamientos: [],
			subfraccionamiento: localStorage.getItem('subfrac') || '0',
            showModal: false,
            hidden: false,
            page: 1,
            filterData: [],
			keys: [],
			dataSet: false,
			filter: '',
			newButton: true,
			selectSub: true,
			btnAux: false,
			showDate: false,
			desde: moment(this.props.desde).format('YYYY-MM-DD'),
			hasta: moment(this.props.hasta).format('YYYY-MM-DD'),
        }
    }

    componentDidMount() {
		this.getSubFraccionamientos();

		if(this.props.newButton == null) {
			this.setState({newButton: true});
		}
		else {
			this.setState({newButton: this.props.newButton});
		}

		if(this.props.selectSub == false) {
			this.setState({selectSub: this.props.selectSub})
		}
		if(this.props.btnAux == true) {
			this.setState({btnAux: this.props.btnAux})
		}
		if(this.props.showDate == true) {
			this.setState({showDate: this.props.showDate})
		}

		if (this.props.desde != this.state.desde) {
			this.setState({
				desde: moment(this.props.desde).format('YYYY-MM-DD'),
			});
		}

		if (this.props.hasta != this.state.hasta) {
			this.setState({
				hasta: moment(this.props.hasta).format('YYYY-MM-DD'),
			});
		}

		// btnAux
	}

	async componentDidUpdate(prevProps, prevState) {
		if (this.props.desde != prevProps.desde) {
			await this.setState({
				desde: moment(this.props.desde).format('YYYY-MM-DD'),
			});
		}

		if (this.props.hasta != prevProps.hasta) {
			await this.setState({
				hasta: moment(this.props.hasta).format('YYYY-MM-DD'),
			});
		}
	}

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }

    reload() {
        this.getSubFraccionamientos()
	}

    save = data => {
        this.edditSub(data);
    }

    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;

        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        //console.log(param1);

        if (param1 === "todo") {
            this.setState({ subfraccionamientos: this.state.filterData });
            form.textFilter.value = "";
        }
        else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ subfraccionamientos: filterData });
        }

    }

	subChange(event) {
		this.setState({subfraccionamiento: event.target.value});
		localStorage.setItem('subfrac', event.target.value);
		if(this.props.subChange) {
			this.props.subChange(event.target.value);
		}
	}

	handleNuevo() {
		if(this.props.nuevo) {
			this.props.nuevo();
		}
	}

	setFilter() {
		if(this.props.setFilter) {
			this.props.setFilter(this.state.filter)
		}
	}

	changeDate(newDate){
		if(this.props.changeDate) {
			this.props.changeDate(newDate)
		}
	}

	async filterSearch(event){
		let e = event;
		await this.setState({filter: event.target.value });
		
	}

	/*async keuUp(e){
		if(e.keyCode === 13){
			this.setFilter();
		}
    }*/

    async exportExcel() {
        this.setState({excelLoading: true});

        if(this.props.dataSet) {
			try {
				let data;
				const { dataSet, title, context, dateFormat } = this.props;
				if (Array.isArray(dataSet)) {
					if (dataSet.length <= 0) {
						cogoToast.warn('Sin datos para exportar.', {
							position: 'bottom-right'
						});
						this.setState({excelLoading: false});
						return;
					}
					if (!dataSet[0]) {
						cogoToast.error('No se han establecido las configuraciones para reporte.', {
							position: 'bottom-right'
						});
						this.setState({excelLoading: false});
						return;
					}
					if (!dataSet[0].excelColumnsShow && !dataSet[0].excelColumnsName) {
						cogoToast.error('No se han establecido las configuraciones para reporte.', {
							position: 'bottom-right'
						});
						this.setState({excelLoading: false});
						return;
					}
					// Definición de columnas
					let excelColumnsShow = dataSet[0].excelColumnsShow;
					excelColumnsShow = excelColumnsShow.split(',');
					let excelColumnsName = dataSet[0].excelColumnsName;
					excelColumnsName = excelColumnsName.split(',');
					let excelColumnsType = dataSet[0].excelColumnsType;
					excelColumnsType = excelColumnsType.split(',');
					// Construir las filas
					let rows = [];
					dataSet.forEach(element => {
						const object = {};
						excelColumnsShow.forEach((columnShow, index) => {
							const column = excelColumnsName[index];
							const row = element[columnShow];
							object[column] = row;
						});
						rows.push(object);
					});
					//Datos a enviar al API
					data = {
						columns: excelColumnsName,
						rows,
						excelColumnsType,
						titulo: title.toUpperCase(),
						fraccionamiento: context.fraccionamiento || 'Appmosphera',
						usuario: context.usuario || '',
						fecha: moment().format('DD/MM/YYYY HH:mm'),
						dateFormat,
						tz: moment.tz.guess()
					};

					const response = await request.post('/excel/exportar', data);
					const { buffer } = response;
					if (buffer) {
						cogoToast.success('Generando archivo.', {
							position: 'bottom-right'
						});
						const bytes = new Uint8Array(buffer.data);
						const blob = new Blob([bytes]);
						const link = document.createElement('a');
						link.href = window.URL.createObjectURL(blob);
						const fileName = `${this.props.title}_${moment().format('YYMMDDHHmm')}.xlsx`;
						link.download = fileName;
						link.click();
					} else {
						cogoToast.error(response.message || 'No se pudo crear el documento de excel.', {
							position: 'bottom-right'
						});
					}
				}
			} catch(error) {
				cogoToast.error(error.message, {
					position: 'bottom-right'
				});
			}
		} else {
            cogoToast.error('No se pudo crear el documento de excel.', {
                position: 'bottom-right'
            });
		}
        this.setState({excelLoading: false});
    }

    render() {
        return (
			<div className="container container-control column full">
				<div className="white-space-32" />
				<div className="navigation align-center">
					<div className="justify-start">
						<h3 className="weight-semi">{this.props.title}</h3>
					</div>
					<div className="buttons-div justify-end">
                            	{this.props.excelButton === false ?
							<button type="button" className="btn btn-disable btn-small color-white" disabled>
								<i className="far fa-file-excel font-text" />
                                    &nbsp; Excel
                                	</button>
							:
							this.state.excelLoading ?
								<button type="button" className="btn btn-primary btn-small color-white">
									<i className="fas fa-spinner fa-spin" />
								</button>
								:
								<button type="button" className="btn btn-primary btn-small color-white" onClick={this.exportExcel.bind(this)}>
									<i className="far fa-file-excel font-text" />
                                        &nbsp; Excel
                                    </button>
						}&nbsp;
						{this.props.newButton ?
							<button type="button" className="btn btn-primary btn-small color-white" onClick={this.handleNuevo.bind(this)}>
								<i className="fas fa-plus font-text" />
									&nbsp; Nuevo
								</button>
							:
							<button type="button" className="btn btn-disable btn-small color-white">
								<i className="fas fa-plus font-text" />
									&nbsp; Nuevo
								</button>

						}
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="row justify-between align-center">
					<div className="column">
						{/*{this.state.showDate &&
								<div className="row align-center">
									<p className="font-regular weight-medium color-dark">Desde:</p>&nbsp;
									<DatePicker
										className="input-filter half input-filter-date"
										selected={this.state.desde}
										onChange={(value)=>{ this.changeDate({desde: value })}}
										dateFormat="dd/MM/yyyy"
										locale="es"
									/>
									&nbsp;
									<p className="font-regular weight-medium color-dark">Hasta:</p>&nbsp;
									<DatePicker
										className="input-filter input-filter-date"
										selected={this.state.hasta}
										onChange={(value)=>{ this.changeDate({hasta: value })}}
										dateFormat="dd/MM/yyyy"
										locale="es"
									/>
								</div>
						}*/}
						
						{this.state.selectSub &&
						<div className="row align-center">
							{this.state.subfraccionamientos.length > 1 ? 
							<select name="subfraccionamiento" id="subfraccionamiento" className="input input-select"
								value={this.state.subfraccionamiento}
								onChange={this.subChange.bind(this)}>
									<option value="0">{this.props.labelDefault ?this.props.labelDefault :'Todos'}</option>
								{  
									this.state.subfraccionamientos.map((subfraccionamiento, key) => (
									<option value={subfraccionamiento.id_subfraccionamiento} key={key}>{subfraccionamiento.nombre}</option>)) 
								}
							</select> :
							<select name="subfraccionamiento" id="subfraccionamiento" className="input input-select"
								value={this.state.subfraccionamiento} disabled>
								{
									this.state.subfraccionamientos.map((subfraccionamiento, key) => (
										<option value={subfraccionamiento.id_subfraccionamiento} key={key}>{subfraccionamiento.nombre}</option>))
								}
							</select>
							} &nbsp;
						</div>
						}
						{/*{this.state.btnAux &&
						<div className="row align-center">
							<button type="button" className="btn btn-primary btn-small color-white" onClick={this.props.fastSend.bind(this)}>
									<i className="fas fa-paper-plane font-text" />
									&nbsp; Envío Rápido
							</button> 
							 &nbsp;
						</div>
						}*/}
					</div>
					{this.state.btnAux && <div className="column" style={{width: '20%'}}>
							<div className="row align-center">
								<button type="button" className="btn btn-primary btn-small color-white" onClick={this.props.fastSend.bind(this)}>
									<i className="fas fa-paper-plane font-text" />
									&nbsp; Envío Rápido
							</button>
							 &nbsp;
						</div>
					</div>}
					<div className="filts column">
						<div className="row align-center">
							<div className="search-div">
								<div className="input-clean">
									<input
										className="input input-filter"
										type="text"
										name="textFilter"
										id="filterData"
										placeholder="Buscar"
										/*value={this.state.filter}*/
										onChange={(event) => this.props.setFilter(event.target.value) /*this.filterSearch(event)*/} /*onKeyUp={(event) => this.keuUp(event)}*/>
									</input>
									<i className="fas fa-search font-small" /*onClick={this.setFilter.bind(this)}*/></i>
									{/*<i className="fas fa-times" onClick={() => {
										this.setState({ filter: '' });
										if (this.props.setFilter) {
											this.props.setFilter('');
										}
									}}></i>*/}
									</div>
									{/*<button className="btn btn-filter btn-small" onClick={this.setFilter.bind(this)}>
									<i className="fas fa-search font-small">
									</i>
								</button>&nbsp;*/}

								{/*<p className="font-regular weight-medium color-dark">Filtrar:</p>&nbsp;
								<div className="input-clean">
									<input
										className="input-filter"
										type="text"
										name="textFilter"
										id="filterData"
										value={this.state.filter}
										onChange={(event) => this.filterSearch(event)} onKeyUp={(event) => this.keuUp(event)}>
									</input>&nbsp;
									<i className="fas fa-times" onClick={() => {
										this.setState({filter:''});
										if(this.props.setFilter) {
											this.props.setFilter('');
										}
									}}></i>
								</div>
								<button className="btn btn-filter btn-small" onClick={this.setFilter.bind(this)}>
									<i className="fas fa-search font-small">
									</i> &nbsp; Buscar
								</button>&nbsp;*/}
							</div>
							
						</div>
					</div>
				</div>
				<div className="white-space-16" />
				{this.state.showDate && <div className="row-responsive full">
					<div className="data-from column full">
						<h5 className="color-black">DESDE:</h5>
						<input
							type="date"
							id="startdate"
							name="startdate"
							className="input input-text"
							placeholder="Desde"
							value={this.state.desde}
							onChange={(e) => { this.changeDate({ desde: e.target.value }) }}
						/>
					</div>
					<div style={{ width: '16px' }}></div>
					<div className="data-to column full">
						<h5 className="color-black">HASTA:</h5>
						<input
							type="date"
							id="enddate"
							name="enddate"
							className="input input-text"
							placeholder="Hasta"
							value={this.state.hasta}
							onChange={(e) => { this.changeDate({ hasta: e.target.value }) }}
						/>
					</div>
				</div>}
				<div className="white-space-16" />
			</div>
        );
    }

    handleOpen() {
        this.setState({ showModal: true })
    }

    handleClose() {
        this.setState({ showModal: false })
    }

    // traer subfraccionamientos
    async getSubFraccionamientos() {
		this.setState({loadingSub: true});
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
        let data = { Idsub: fraccionamiento, filter: 1 }
        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
        if (response && !response.error) {
            if (response.subfraccionamiento && !response.empty) {
				// console.log(response.subfraccionamiento);
				if (response.subfraccionamiento.length === 1) {
					this.setState({
						subfraccionamientos: response.subfraccionamiento,
						filterData: response.subfraccionamiento,
						subfraccionamiento: response.subfraccionamiento[0].id_subfraccionamiento
					});
					localStorage.setItem('subfrac', response.subfraccionamiento[0].id_subfraccionamiento);
				} else {
					this.setState({
						subfraccionamientos: response.subfraccionamiento,
						filterData: response.subfraccionamiento
					});
				}
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    subfraccionamientos: []
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
		}
		this.setState({loadingSub: false});
    }

    // guardar subfraccionamiento
    async handleSubmit(event) {
		this.setState({loadingSave: true});
        event.preventDefault();
        const form = event.target;
        let data = {
            IdFraccionamiento: localStorage.getItem('frac'),
            Nombre: form.name.value,
            Direccion: form.address.value,
            Telefono: form.phone.value
        };

        const response = await request.post("/subfraccionamientos/create/subfraccionamiento", data);

        if (response && !response.error) {
            if (response.created && !response.empty) {
                this.setState({
                    reload: true,
                    loading: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false
            });
        }
        this.getSubFraccionamientos()
        this.setState({ showModal: false, loadingSave: false });
    }

    async edditSub(data) {
        const response = await request.post("/subfraccionamientos/eddit/subfraccionamiento", data);
        if (response.created) {
            this.getSubFraccionamientos()
        }
    }
}

export default Consumer(SubFRaccPage);