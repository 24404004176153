/**
 *  views.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Controllador de las vistas con las rutas
 * 	@process: 3
*/

import React from 'react';
import Routes from './routes';
import { Helmet } from 'react-helmet';

//API PUSH NOTIFICATIONS
//Produccion
let PUSH_API = "88653a19-ad70-4258-8f42-5180848f1183"
//Test
//let PUSH_API = "bccb4af4-79ed-49b6-bdea-6531761ffa3c";
if (process.env.NODE_ENV !== 'production') {
    PUSH_API = "1e5dab3c-a195-4045-b9bc-61e42f927789";
}

const View = () => (
    <>
        <Helmet>
            <script>
                {`
                    var OneSignal = window.OneSignal || [];
                    OneSignal.push(function() {
                        OneSignal.init({
                            appId: "${PUSH_API}",
                            notifyButton: {
                                enable: false,
                            },
                            promptOptions: {
                                actionMessage: "Appmosphera necesita permisos para recibir notificaciones.",
                                acceptButtonText: "PERMITIR",
                                cancelButtonText: "NO GRACIAS"
                            },
                            ${process.env.NODE_ENV !== 'production'? 'subdomainName: "devappmosphera"' : ''}
                        });
                        OneSignal.showSlidedownPrompt();
                    });
                `}
            </script>
        </Helmet>
        <Routes></Routes>
    </>
);

export default View;