class sortableData {
    sortBy(key, data, direction) {
        let sub = data;
        sub.sort((a, b) => {
            if (key === 'nombre' || key === 'nombre_subfrac' || key === 'direccion' || key === 'usuario' || key === 'perfil' || key === 'descripcion' || key === 'nombre_sf' || key === 'nombre_subfrac' || key === 'notificacion_texto' || key === 'comentarios' || key === 'invitado' || key === 'quejaDesripcion' || key === 'Descripcion' || key === 'fraccionamiento') {
                if (a[key].toUpperCase() < b[key].toUpperCase()) {
                    return direction === 'asc' ? 1 : -1;
                }
                if (a[key].toUpperCase() > b[key].toUpperCase()) {
                    return direction === 'asc' ? -1 : 1;
                }
            }

            if (a[key] < b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? -1 : 1;
            }

            return 0;
        });

        const dir = direction === 'asc' ? 'desc' : 'asc';

        return { items: sub, direction: dir };
    }
}

export default sortableData;