import React, { Component } from "react";
import { Consumer } from '../../../context';
import DinamicModal from '../../dinamicModal/dinamicModal';
import PedidosDetail from './detail';
import NumberFormat from "react-number-format";
import moment from 'moment-timezone';
import Request from '../../../core/httpClient';
import cogoToast from 'cogo-toast';

const request = new Request();

let values = {};

class PedidosTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen:        false,
            id_restaurante:     this.props.id_restaurante,
            id_pedido:          0,
            pedido:             {},
            lineas:             [],
            user:               {}
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({
                user: {
                    id_usuario: user.id.id_usuario,
                }
            });

        }
    }

    async openModal(idModal, pedido) {
		await this.setState({ pedido: pedido, message: null });
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
        }
        this.setState({isModalOpen: true});
        this.getLineas(pedido.id_pedido);
    }

    async getLineas(id_pedido) {
        this.setState({loadingLineas: true, messageLineas: null});
        const res = await request.post('/restaurantes/pedidos/getlineas', { id_pedido });
        if (res.lineas) {
            this.setState({lineas: res.lineas});
        } else {
            this.setState({lineas: []/*, messageLineas: res.message*/});
        }
        this.setState({loadingLineas: false});
    }

    render() {
        let pedidos = [];
        if(Array.isArray(this.props.pedidos))
            pedidos = this.props.pedidos;
            console.log(pedidos);
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="row">
                    <table className="full">
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="text-left">
                                    <div className="row">
                                        Fraccionamiento &nbsp;
                                        <div className="align-center">
                                            {this.props.filterColumn.col === 'fraccionamiento' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'fraccionamiento')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'fraccionamiento')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'fraccionamiento')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="row">
                                        Unidad &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'vivienda' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'vivienda')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'vivienda')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'vivienda')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="row">
                                        Fecha Entrega &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'fecha_entrega' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'fecha_entrega')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'fecha_entrega')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'fecha_entrega')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="row">
                                        Estado &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'estado' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'estado')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'estado')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'estado')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-right">
                                    <div className="row">
                                        Importe &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'total' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'total')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'total')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'total')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center th-options-small">Detalle</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pedidos.slice((this.props.page - 1) * 7, this.props.page * 7).map((pedido, key) => (
								<React.Fragment key={pedido.id_pedido}>
									<tr>
                                        <td className="text-left">{`${pedido.fraccionamiento} - ${pedido.subfraccionamiento}`}</td>
										<td className="text-left">{pedido.vivienda}</td>
                                        <td className="text-left">{moment(pedido.fecha_entrega).format('DD/MM/YYYY hh:mm a')}</td>
                                        <td className="text-left">{pedido.estado}</td>
                                        <td className="text-right">
                                            <NumberFormat
                                                value               =   {pedido.total}
                                                displayType         =   {"text"}
                                                thousandSeparator   =   {true}
                                                prefix              =   {"$ "}
                                                decimalScale        =   {2}
                                                fixedDecimalScale   =   {true}
                                            />
                                        </td>
										<td className="text-left">
                                            <button 
                                                className="btn-full justify-center align-center btn-mini btn-secondary color-white"
                                                type="button"
                                                onClick={this.openModal.bind(this, 'pedidoDetail', pedido)}
                                            >
                                                <i className="fas fa-utensils"></i>
                                            </button>
										</td>
									</tr>
								</React.Fragment>
                            ))}

                        </tbody>
                    </table>
                </div>
                <DinamicModal
                    idModal             =   {'pedidoDetail'}
                    sizeModalContainer  =   {'big'}
                    title               =   {'DETALLE DEL PEDIDO'}
                    success				=	{this.handleEdit.bind(this)}
					showBtnSuccess		=	{this.props.escritura}
					btnTextSuccess		=	{"ACTUALIZAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
                    closeModal          =   {() => this.setState({isModalOpen: false})}
                >
                    <PedidosDetail
                        isModalOpen     = {this.state.isModalOpen}
                        pedido          = {this.state.pedido}
                        lineas          = {this.state.lineas}
                        loadingLineas   = {this.state.loadingLineas}
                        getValues       = {this.getValues.bind(this)}
                    />
                </DinamicModal>
            </div>
        )
    }

    getValues(data) {
        values = data;
    }

    async handleEdit(event) {
        event.preventDefault();
        const data = {
            pedido: values,
            user: this.state.user
        }
        this.setState({loadingSave: true, message: null});
        const res = await request.post('/restaurantes/pedidos/update', data);
        if (res.updated) {
            this.props.reload();
            cogoToast.success('Datos de pedido actualizados.', {
                position: 'bottom-right',
            });
        } else {
            //this.setState({message: res.message || 'No se pudo actualizar el restaurante.'});
            cogoToast.error('No se pudieron actualizar datos de pedido.', {
                position: 'bottom-right',
            });
        }
        this.setState({loadingSave: false});
    }
}

export default Consumer(PedidosTable);
