import React, { Component } from "react";
import DinamicModal from '../../dinamicModal/dinamicModal';
import EditForm from './form';
import ModalBorrar from '../../modals/modalDelete';
import Request from '../../../core/httpClient';
import cogoToast from 'cogo-toast';

const request = new Request();

let values = {};

class ConfiguracionTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            restaurante:        {},
            isModalOpen:        false,
            data:               {},
            fraccionamiento: localStorage.getItem('frac') || 0,
        }
    }

    async openModal(idModal, restaurante) {
		await this.setState({ restaurante, message: null, id_restaurante_row: restaurante.id_restaurante });
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
        }
        this.setState({isModalOpen: true});
    }

    openModalDelete(res) {
		this.setState({id_restaurante: res.id_restaurante, id_restaurante_row: res.id_restaurante});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

    async checkCats(id_categoria) {
        const res = await request.post('/restaurantes/check/categoria', {id_categoria});
        if (!res.checked) {
            if (res.error) {
                cogoToast.error(res.message, {
                    position: 'bottom-right'
                });
                return false;
            }
            cogoToast.error('Esta categoría esta asignada a un plato, no se puede eliminar.', {
                position: 'bottom-right'
            });
            return false;
        } else {
            return true;
        }
    }

    async checkHors(id_horario) {
        const res = await request.post('/restaurantes/check/horario', {id_horario});
        if (!res.checked) {
            if (res.error) {
                cogoToast.error(res.message, {
                    position: 'bottom-right'
                });
                return false;
            }
            cogoToast.error('Este horario esta asignado a un plato, no se puede eliminar.', {
                position: 'bottom-right'
            });
            return false;
        } else {
            return true;
        }
    }

    render() {
        let restaurantes = [];
        if(Array.isArray(this.props.restaurantes))
            restaurantes = this.props.restaurantes;
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="row">
                    <table className="full">
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="text-left">
                                    <div className="row">
                                        Nombre &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-start">Activo</th>
                                <th className="text-center th-options-small">Editar</th>
                                <th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {restaurantes.slice((this.props.page - 1) * 7, this.props.page * 7).map((res, key) => (
								<React.Fragment key={key}>
                                    <tr className={this.state.id_restaurante_row === res.id_restaurante && 'row-active'}>
										<td className="text-left">{res.nombre}</td>
                                        <td>
                                            <label className="container-check" >
                                                <input
                                                    type="checkbox"
                                                    checked={res.activo? true : false}
                                                    readOnly
                                                    disabled/>
                                                <span className="checkmark" ></span>
                                            </label>
                                        </td>
										<td className="text-left">
                                            <button 
                                                className="btn-full justify-center align-center btn-mini btn-secondary color-white"
                                                type="button"
                                                onClick={this.openModal.bind(this,"editRestaurante", res)} 
                                                // disabled = {!this.props.escritura}
                                                >
                                                    <i className="font-small fas fa-pen" />
                                            </button>
										</td>
										<td className="text-center">
                                            <button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
                                                    type		=	'button' 
                                                    onClick		=	{this.openModalDelete.bind(this, res)} 
                                                    style		=	{{ padding: '5px' }} 
                                                    disabled	=	{!this.props.escritura}
                                            >
                                                <i className="font-text fas fa-trash-alt " />
                                            </button>
										</td>
									</tr>
								</React.Fragment>
                            ))}

                        </tbody>
                    </table>
                </div>
                <DinamicModal
                    idModal             =   {'editRestaurante'}
                    title               =   {'Editar restaurante'}
                    success				=	{this.handleSubmit.bind(this)}
					showBtnSuccess		=	{this.props.escritura}
					btnTextSuccess		=	{"ACTUALIZAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
                    closeModal          =   {() => this.setState({isModalOpen: false})}
                    sizeModalContainer  =   {'big'}
                >
                    <EditForm
                        validateCats    = {true}
                        checkCats       = {this.checkCats.bind(this)}
                        validateHors    = {true}
                        checkHors       = {this.checkHors.bind(this)}
                        isModalOpen     = {this.state.isModalOpen}
                        restaurante     = {this.state.restaurante}
                        getValues       = {this.getValues.bind(this)}
                    />
                </DinamicModal>

                <ModalBorrar
					id          =   {this.state.id_restaurante}
					delete      =   {this.handleDelete.bind(this)}
                />
            </div>
        )
    }

    getValues(data) {
        values = data;
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({loadingSave: true, message: null});
        const res = await request.post('/restaurantes/update', { ...values, idFraccionamiento: this.state.fraccionamiento });
        if (res.updated) {
            this.props.reload();
            cogoToast.success('Datos de restaurante actualizados.', {
                position: 'bottom-right',
            });
        } else {
            //this.setState({message: res.message || 'No se pudo actualizar el restaurante.'});
            cogoToast.error('No se pudieron actualizar datos de restaurante.', {
                position: 'bottom-right',
            });
        }
        this.setState({loadingSave: false});
    }

    async handleDelete(id) {
        let data = {
            id_restaurante: id,
        }
        const response = await request.post("/restaurantes/delete", data);
        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                //this.setState({ message: response.message, });
                this.props.reload();
                cogoToast.success('Restaurante eliminado.', {
                    position: 'bottom-right',
                });

            } else {
                this.setState({
                    empty: true,
                    //message: response.message,
                    loading: false
                });
                cogoToast.error('No se pudo eliminar restaurante.', {
                    position: 'bottom-right',
                });
            }
        } else {
            this.setState({
                empty: true,
                //message: response.message,
                loading: false
            });
            cogoToast.error('No se pudo eliminar restaurante.', {
                position: 'bottom-right',
            });
        }
    }
}

export default ConfiguracionTable;
