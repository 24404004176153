
/**
 *  routes.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 4
 */

import React, { Component, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";

// Conexión con el Context API
import { GlobalContext, Consumer } from "../context";

// Páginas Web
import Login from '../pages/login';

import toaster from "toasted-notes";
import Error from '../components/errors/error';
//Restaurantes
import Res_Config from '../pages/restaurantes/configuracion';
import Res_Platos from '../pages/restaurantes/platos';
import Res_Pedidos from '../pages/restaurantes/pedidos';
import PasswordForgot from "../pages/passwordForgot";
import PasswordReset from "../pages/passwordReset";
import cogoToast from 'cogo-toast';
import "toasted-notes/src/styles.css";
import {Howl} from 'howler';
/**Socket */
import Socket from './socket';

import Request from './httpClient';

const req = new Request();

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={
            props => {
                const usuario = window.localStorage.getItem("iGateAdminR");
                if (usuario) {
                    return <Component {...props} />;
                } else {
                    let path = window.location.pathname;
                    if(path.includes(".")){
                        window.location = "https://documentos.appmosphera.com/"+path;
                    }
                    
                    return (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    );
                }
            }
        }
    />
);

/*const VigenciaPassword = Consumer(({ context }) => {
	let history = useHistory();
	const { location } = history;

	async function validarVencimiento(usuario) {
		const res = await req.post('/users/password/vencimiento', usuario);
		if (!res.error) {
			if (res.vencida) {
				const modal = document.getElementById('expiry-change-password');

				if (modal) {
					context.setExpiratePassword(true);
					modal.classList.remove('hide-dinamic-modal');
					modal.classList.add('show-dinamic-modal');
				}
			}
		}
	}

	useEffect(() => {
		if (!(location.pathname === '/login' || location.pathname === '/' || location.pathname === '/contraseña/actualizar')) {
			const usuario = window.localStorage.getItem("iGateAdmin");
                if (usuario) {
					validarVencimiento(JSON.parse(usuario));
				}
		}
	}, [location.pathname]);

	return <CambiarPassword />
});*/

const Routes = (props) => {
	const [beepSond, setbeepSond] = useState('https://documentos.appmosphera.com/sounds/beep.mp3');
	const [sound, setSound] = useState({
		sound:       new Howl({
			src:        'https://documentos.appmosphera.com/sounds/beep.mp3',
			html5:      true,
			// loop:       true,
		})
	});

	useEffect(() => {
		console.log("Entré");
		Socket.connect();

        //Socket.emit('action', { type: 'listAdmin', data:{idfrac: 2} });
		const idUser = localStorage.getItem('uRest');
		if (idUser) {
			Socket.emit('action', { type: 'listAdminRest', data: { iduser: localStorage.getItem('uRest') } });
		}

		Socket.on('newPedidoRestaurante', (data) => {
			console.log("Llegó pedido");
			// toaster.notify(`Se ha realizado un nuevo pedido en restaurantes.`, {
			// 	duration: 5000
            // });  
			cogoToast.info('Se ha realizado un nuevo pedido en restaurantes.');
		});
    });

    function renderRoutes() {
        // RUTAS PRIVADAS
        let routes = [
            {path:'/restaurantes/configuracion',                component:Res_Config,               private:false},
            {path:'/restaurantes/platos',                       component:Res_Platos,               private:false},
            {path:'/restaurantes/pedidos',                      component:Res_Pedidos,              private:false},
		];

        let routesRender = [];
        
        routesRender = routes.map( (route, index)  =>{
			if (route.private)
            	return <PrivateRoute key={index} exact path ={route.path}  component={route.component} />
			else
				return <Route key = {index} exact path = {route.path} component = {route.component} />
        });

        routesRender.push(<Route exact path ={'/'} component={Login} key={routes.length + 2}/>);
        routesRender.push(<Route exact path ={'/login'} component={Login} key={routes.length + 3}/>);
		routesRender.push(<Route exact path={"/password/forgot"} component={PasswordForgot} key={"password/forgot"}/>);
		routesRender.push(<Route exact path={"/password/reset/:token"} component={PasswordReset} key={"password/reset"}/>);
        routesRender.push(<PrivateRoute component={Error} key={routes.length + 11}/>);
        
        return routesRender;
    }

	return (
		<GlobalContext>
			<BrowserRouter>
				{/*<VigenciaPassword />*/}
				<div className="flex main">
					<div className="column full">
						<Switch>
							{renderRoutes()}
						</Switch>
					</div>
				</div>
			</BrowserRouter>
		</GlobalContext>
	);
}


export default Routes;